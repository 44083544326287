<template>
  <div v-if="currentRouteName === 'Transact' && rendered">
    <h2
      class="container mx-auto w-11/12 text-lg text-left font-bold mb-2 pt-10 text-shiftlydarkblue"
    >
      Transact
    </h2>
    <h3
      class="container mx-auto w-11/12 text-md text-left text-shiftlydarkblue"
    >
      Please choose one of the following options shown below.
    </h3>
    <div class="py-10 flex flex-col">
      <div class="bg-white container mx-auto w-11/12 rounded-lg shadow-md">
        <div class="flex flex-wrap py-5 justify-center" style="cursor: auto">
          <div
            v-if="
              this.client.service_level === 'standard' &&
              this.client.trading_status === 'active_not_submitting_aits'
            "
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-2.5">
              <i
                class="fa-solid fa-money-bill-transfer text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-2 text-shiftlydarkblue">
              Book <br />
              Trade
            </h3>
            <div class="flex space-x-2 justify-center">
              <button
                type="button"
                class="w-28 py-4 px-5 my-7 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                @click.prevent="checkNetWorth"
              >
                Complete
              </button>
            </div>
          </div>
          <div
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-2.5">
              <i
                class="fa-solid fa-hand-holding-dollar text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-2 text-shiftlydarkblue">
              Request <br />
              Withdrawal
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/transact/withdrawal">
                <button
                  class="w-28 py-4 px-5 my-7 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>
          <div
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-2.5">
              <i
                class="fa-solid fa-clock-rotate-left text-3xl text-shiftlyorange"
              ></i>
            </div>
            <h3 class="font-bold mb-1 text-shiftlydarkblue">
              Request <br />
              Transaction History
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/transact/transaction">
                <button
                  class="w-28 py-4 px-5 mt-2 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>
          <div
            class="p-6 border-2 border-gray-200 rounded-lg h-60 w-40 xl:w-48 m-4"
          >
            <div class="mb-2.5">
              <i class="fa-solid fa-chart-line text-3xl text-shiftlyorange"></i>
            </div>
            <h3 class="font-bold mb-1 text-shiftlydarkblue">
              View <br />
              Trading <br />
              Summary
            </h3>
            <div class="flex space-x-2 justify-center">
              <router-link to="/transact/summary">
                <button
                  class="w-28 py-4 px-5 mt-2 bg-shiftlyblue hover:bg-shiftlydarkblue text-white rounded-md"
                >
                  Complete
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-white container mx-auto w-11/12 mt-10 rounded-lg shadow-md"
      >
        <div
          class="block bg-gradient-to-r from-shiftlyblue to-shiftlylightblue text-white h-11 shadow-md rounded-t-lg"
        >
          <p class="p-2.5 text-white font-bold">
            30 Day Average Trading Returns
          </p>
        </div>
        <div class="m-5">
          <line-chart
            :data="averageData"
            suffix="%"
            :colors="['#219af2', '#ffbb11', '#0447e0']"
            :curve="false"
            :round="2"
            :zeros="true"
            :dataset="{ borderWidth: 2 }"
          ></line-chart>
        </div>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import ClientService from "../services/client.service";
import TokenService from "../services/token.service";
import EventBus from "../common/EventBus";

export default {
  name: "TransAct",
  data() {
    return {
      rendered: false,
      client: [],
      averageData: [],
      fullUserName: localStorage.getItem("fullUserName"),
    };
  },
  beforeCreate() {
    var url = TokenService.getClientUrl();
    ClientService.getClient(url).then((client) => {
      this.client = client.data;
      if (this.client !== "") {
        if (this.client.status != "onboarded") {
          this.$router.push("/home");
        }
        ClientService.getBatchProfits().then((profits) => {
          this.averageData = this.transformData(profits.data);
          this.rendered = true;
        });
      }
    });
  },
  mounted() {
    EventBus.dispatch("updateUserName");
    EventBus.dispatch("updateClientStatus");
    EventBus.dispatch("updateServiceLevel");
    EventBus.dispatch("updateCreditChoice");
    EventBus.dispatch("updateReferralActive");
  },
  updated() {
    document.title = "Shiftly Client Portal - Transact";
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    checkNetWorth() {
      if (this.client.net_worth == "") {
        this.$swal(
          "Missing Information!",
          "Please complete your Estimated Net Worth and Submit. You can then proceed to book your trade.",
          "info"
        );
        this.$router.push("/profile/personal-information");
      } else {
        this.$router.push("/transact/booking");
      }
    },
    //Transform 30 Day Avg Data
    transformData(data) {
      const latestData = data.slice(0, 30);

      const minData = [];
      const avgData = [];
      const maxData = [];

      latestData.forEach((entry) => {
        minData.push([entry.created_at, entry.net_profit_min]);
        avgData.push([entry.created_at, entry.net_profit_avg]);
        maxData.push([entry.created_at, entry.net_profit_max]);
      });

      return [
        { name: "Minimum", data: minData },
        { name: "Average", data: avgData },
        { name: "Maximum", data: maxData },
      ];
    },
  },
};
</script>

<style scoped></style>
